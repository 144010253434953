





import { Component, Vue } from 'vue-property-decorator'
import { AuthActions } from '@/shared/store/auth/auth.actions'
import Echo from 'laravel-echo'
import { GlobalGetters } from '@/shared/store/global/global.getters'
import { Routes } from '@/shared/router'

@Component
export default class Logout extends Vue {
  created() {
    this.echoServerInstance && this.echoServerInstance.disconnect()
    this.$store.dispatch(AuthActions.logout)
      .then(() => this.$router.push({ name: Routes.home })).catch(() => {})
  }

  get echoServerInstance(): Echo {
    return this.$store.getters[GlobalGetters.getEchoServerInstance]
  }
}
